import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=135237e5&"
import script from "./MenuBar.vue?vue&type=script&lang=ts&"
export * from "./MenuBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandAlphabetMegaMenu: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/brand/AlphabetMegaMenu.vue').default,AppHeaderNavPopover: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/app/header/NavPopover.vue').default,BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/Icon.vue').default})
